import * as React from 'react';
import isBrowser from '~/utils/isBrowser';

function useLockBodyScroll(lock) {
  React.useLayoutEffect(() => {
    if (isBrowser && lock) {
      // const originalStyle = window.getComputedStyle(document.body).overflow;
      const originalStyle = window.getComputedStyle(document.body);

      document.body.style.overflow = 'hidden';
      document.body.style['touch-action'] = 'none';
      document.body.style['-webkit-overflow-scrolling'] = 'none';
      document.body.style['overscroll-behavior'] = 'none';

      return () => (document.body.style = originalStyle);
    }
  }, [lock]);
}

export default useLockBodyScroll;
