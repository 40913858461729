import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import firebase from '~/service/firebase';
import {
  emitNotificationEvent,
  NOTIFICATION_CODE,
} from '~/service/events/notificationEvent';
import {
  getRefreshEventSubject,
  REFRESH_KEY,
} from '~/service/events/refreshSubject';
import isBrowser from '~/utils/isBrowser';
import shopify from '~/service/shopify';
import { navigate } from 'gatsby';
import { mapOrder } from '~/utils/arrayHelper';
import { useUserLocation } from '~/provider/UserLocationProvider';
import queryString from 'query-string';

const StoreContext = React.createContext();

const initialStoreState = {
  updating: false,
  checkout: { lineItems: [], id: undefined },
  cart: {
    count: 0,
    value: 0,
  },
  products: [],
  promotions: [],
  collections: [],
  checkoutProducts: [],
};

const COLLECTION_ORDER = [
  "Mach's dir sälber",
  'Cocktails',
  'Shots',
  'Wein',
  'Bier',
];
const DRINKS_ORDER = [
  'Passion Mule',
  'Basil Smash',
  'Negroni Sbagliato',
  'Mules',
  'Whisky Sour',
  'Gin Tonic',
  'Mojito',
  'Turbo Tony',
  'Red Bull Vodka',
  'Cuba Libre',
  'Whisky Cola',
];

function parseMetafield(metafields) {
  return metafields.map((meta) => ({
    ...meta,
    value: JSON.parse(meta.value),
  }));
}

const StoreProvider = ({ children }) => {
  const { allShopifyCollection } = useStaticQuery(shopifyQuery);
  const { addressQueryParams } = useUserLocation();
  const [featuredList, setFeaturedList] = React.useState([]);

  function getProducts(productList) {
    return productList.map((prod) => ({
      ...prod,
      metafields: parseMetafield(prod.metafields),
      variants: prod.variants.map((variant) => ({
        ...variant,
        metafields: parseMetafield(variant.metafields),
      })),
    }));
  }

  const [store, setStore] = React.useState({
    ...initialStoreState,
    collections: mapOrder(
      allShopifyCollection.edges.map(({ node }) => ({
        ...node,
        products:
          node.title === 'Drinks'
            ? mapOrder(
                getProducts(node.products),
                DRINKS_ORDER,
                (drink) => drink.title
              )
            : getProducts(node.products),
      })),
      COLLECTION_ORDER,
      (col) => col.title
    ),
  });

  React.useEffect(() => {
    (async function loadFeatured() {
      const featuredRef = await firebase
        .firestore()
        .collection('featured')
        .get();

      const retrievedFeaturedList = [];
      featuredRef.forEach((doc) => retrievedFeaturedList.push(doc.data()));
      setFeaturedList(retrievedFeaturedList);
    })();
  }, []);

  const featuredProducts = React.useMemo(() => {
    return featuredList
      .map((feat) => {
        const collectionOfFeatured = store.collections.find(
          (coll) => coll.title === feat.collection
        );
        if (collectionOfFeatured) {
          const featProd = collectionOfFeatured.products.find(
            (prod) => prod.title === feat.product
          );
          if (featProd) {
            return {
              ...featProd,
              title: feat.title || featProd.title,
              collection: collectionOfFeatured,
            };
          }
        }
        return null;
      })
      ?.filter((feat) => !!feat);
  }, [featuredList, store.collections]);

  const checkoutId = React.useMemo(() => store?.checkout?.id, [
    store.checkout.id,
  ]);

  const lineItems = React.useMemo(() => store.checkout.lineItems, [
    store.checkout.lineItems,
  ]);

  const isRemoved = React.useRef<boolean>(false);

  const webUrl = React.useMemo(() => {
    if (store?.checkout?.webUrl) {
      return store.checkout.webUrl;
      // return queryString.stringifyUrl({
      //   url: store.checkout.webUrl,
      //   query: addressQueryParams,
      // });
    }
    return null;
  });

  function setCheckoutInState(checkout) {
    if (isBrowser) {
      localStorage.setItem('shopify_checkout_id', checkout?.id || null);
    }
    setStore((prevState) => ({
      ...prevState,
      checkout: checkout || { lineItems: [], id: null },
    }));
  }

  const refreshCheckout = React.useCallback(async () => {
    if (checkoutId) {
      try {
        const fetchedCheckout = await shopify.fetchCheckout(checkoutId);
        if (fetchedCheckout.completedAt) {
          setCheckoutInState();
          emitNotificationEvent({
            message: 'Bestellung erfolgreich aufgegeben',
          });
          navigate('/');
        }
      } catch (e) {
        //
      }
    }
  }, [checkoutId]);

  // LISTEN TO REFERSH EVENT
  React.useEffect(() => {
    const subscription = getRefreshEventSubject(REFRESH_KEY.CHECKOUT).subscribe(
      refreshCheckout
    );
    return () => subscription.unsubscribe();
  }, [refreshCheckout]);

  // LOAD ALL PRODUCTS
  React.useEffect(() => {
    async function loadProducts() {
      try {
        const products = await shopify.fetchAllProducts();

        setStore((prevStore) => ({
          ...prevStore,
          // TODO: Set sold out products
          collections: prevStore.collections.map((collection) => ({
            ...collection,
          })),
          products,
          promotions: products.filter((prod) => prod.productType === 'Promo'),
          checkoutProducts: products.filter(
            (prod) => prod.productType === 'Checkout'
          ),
        }));
      } catch (e) {
        //
      }
    }
    loadProducts();
  }, []);

  function getIsPromoLineItem(lineItem) {
    return lineItem.customAttributes.some((ca) =>
      Object.values(ca).includes('promo')
    );
  }

  // CALCULATE CART ITEMS COUNT AND VALUE
  React.useEffect(() => {
    const notPromoLineItems = lineItems.filter((li) => !getIsPromoLineItem(li));
    setStore((prevStore) => ({
      ...prevStore,
      cart: {
        count: notPromoLineItems.reduce((acc, cur) => acc + cur.quantity, 0),
        value: notPromoLineItems.reduce(
          (acc, cur) => acc + cur.quantity * cur.variant.price,
          0
        ),
      },
    }));
  }, [lineItems]);

  const addVariantsToCart = React.useCallback(
    async (
      variants: Array<{ variantId: number; quantity: number }>,
      hideNotification = false
    ) => {
      setStore((prevState) => ({ ...prevState, updating: true }));

      try {
        const updatedCheckout = await shopify.addLineItems(
          checkoutId,
          variants
        );

        setStore((prevState) => ({
          ...prevState,
          checkout: updatedCheckout,
        }));

        if (!hideNotification) {
          emitNotificationEvent({
            message: "Erfolgreich zum 'Ichaufsbütel' hinzugefügt",
          });
        }
      } catch (e) {
        if (!hideNotification) {
          emitNotificationEvent({
            message: 'Produkt konnte nicht hinzugefügt werden',
            code: NOTIFICATION_CODE.ERROR,
            color: 'sand',
          });
        }
      } finally {
        setStore((prevState) => ({ ...prevState, updating: false }));
      }
    },
    [checkoutId]
  );

  const addVariantToCart = React.useCallback(
    async (
      variantId: number,
      quantity: number,
      customAttributes,
      hideNotification = false
    ) => {
      setStore((prevState) => ({ ...prevState, updating: true }));

      try {
        const updatedCheckout = await shopify.addLineItem(checkoutId, {
          variantId,
          quantity: parseInt(quantity, 10),
          ...(customAttributes ? { customAttributes } : {}),
        });
        setStore((prevState) => ({
          ...prevState,
          checkout: updatedCheckout,
        }));

        if (!hideNotification) {
          emitNotificationEvent({
            color: 'sand',
            message: "Erfolgreich zum 'Ichaufsbütel' hinzugefügt",
          });
        }
      } catch (e) {
        if (!hideNotification) {
          emitNotificationEvent({
            message: 'Produkt konnte nicht hinzugefügt werden',
            code: NOTIFICATION_CODE.ERROR,
          });
        }
      } finally {
        setStore((prevState) => ({ ...prevState, updating: false }));
      }
    },
    [checkoutId]
  );

  // INITIALIZE CHECKOUT
  React.useEffect(() => {
    async function initializeCheckout() {
      if (!checkoutId) {
        // Check for an existing cart.
        const existingCheckoutId = isBrowser
          ? localStorage.getItem('shopify_checkout_id')
          : null;

        if (existingCheckoutId) {
          try {
            const checkout = await shopify.fetchCheckout(existingCheckoutId);
            // Make sure this cart hasn’t already been purchased.
            if (!isRemoved.current && !checkout.completedAt) {
              return setCheckoutInState(checkout);
            }
          } catch (e) {
            localStorage.setItem('shopify_checkout_id', null);
          }
        }

        const newCheckout = await shopify.createCheckout();
        if (!isRemoved.current) {
          setCheckoutInState(newCheckout);
        }
      }
    }
    initializeCheckout();
  }, [checkoutId]);

  React.useEffect(
    () => () => {
      isRemoved.current = true;
    },
    []
  );

  return (
    <StoreContext.Provider
      value={{
        store,
        webUrl,
        featuredProducts,
        addVariantToCart,
        addVariantsToCart,
        updateAttributes: async (attributes) => {
          setStore((prevStore) => ({ ...prevStore, updating: true }));

          if (!checkoutId) {
            return null;
          }

          try {
            const updatedCheckout = Array.isArray(attributes)
              ? await shopify.updateCheckoutAttributes(checkoutId, attributes)
              : await shopify.updateCheckoutAttribute(checkoutId, attributes);
            setStore((prevState) => ({
              ...prevState,
              checkout: updatedCheckout,
            }));
          } catch (e) {
            emitNotificationEvent({
              message: 'Information konnte nicht aktualisiert werden',
              code: NOTIFICATION_CODE.ERROR,
            });
          } finally {
            setStore((prevState) => ({ ...prevState, updating: false }));
          }
        },
        removeLineItem: async (lineItemId) => {
          setStore((prevStore) => ({ ...prevStore, updating: true }));

          try {
            const updatedCheckout = await shopify.removeLineItem(
              checkoutId,
              lineItemId
            );
            setStore((prevState) => ({
              ...prevState,
              checkout: updatedCheckout,
            }));
            emitNotificationEvent({
              message: "Produkt erfolgreich aus dem 'Ichaufsbütel' entfernt",
            });
          } catch (e) {
            emitNotificationEvent({
              message:
                "Produkt konnte nicht aus dem  'Ichaufsbütel' entfernt werden",
              code: NOTIFICATION_CODE.ERROR,
            });
          } finally {
            setStore((prevState) => ({ ...prevState, updating: false }));
          }
        },
        updateLineItem: async (lineItemId, quantity) => {
          setStore((prevStore) => ({ ...prevStore, updating: true }));

          try {
            const updatedCheckout = await shopify.updateLineItem(checkoutId, {
              id: lineItemId,
              quantity: parseInt(quantity, 10),
            });
            setStore((prevState) => ({
              ...prevState,
              checkout: updatedCheckout,
            }));
            emitNotificationEvent({
              message: "'Ichaufsbütel' erfolgreich aktualisiert",
            });
          } catch (e) {
            emitNotificationEvent({
              message: "'Ichaufsbütel' konnte nocht aktualisiert werden",
              code: NOTIFICATION_CODE.ERROR,
            });
          } finally {
            setStore((prevState) => ({ ...prevState, updating: false }));
          }
        },
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};

const shopifyQuery = graphql`
  query {
    allShopifyCollection {
      edges {
        node {
          description
          title
          image {
            src
            id
            localFile {
              childImageSharp {
                gatsbyImageData(width: 600)
              }
            }
          }
          products {
            variants {
              price
              id
              title
              shopifyId
              metafields {
                key
                value
                namespace
              }
            }
            tags
            title
            description
            availableForSale
            productType
            handle
            id
            shopifyId
            metafields {
              key
              value
              namespace
            }
            images {
              originalSrc
              id
              localFile {
                absolutePath
                childImageSharp {
                  gatsbyImageData(width: 600)
                }
              }
            }
          }
          id
          handle
          shopifyId
        }
      }
    }
  }
`;

export const useStore = () => React.useContext(StoreContext);

export default StoreProvider;
