import { BehaviorSubject } from 'rxjs';

export const EVENT_TYPE = {
  NOTIFICATION: 'NOTIFICATION',
  REFRESH: 'REFRESH',
};

interface Event {
  type: keyof typeof EVENT_TYPE;
  [string]: any;
}

export const eventSubject = new BehaviorSubject<Event>(null);
