import * as React from 'react';
import isBrowser from '~/utils/isBrowser';

function useIntersection(ref, options) {
  const [
    intersectionObserverEntry,
    setIntersectionObserverEntry,
  ] = React.useState(null);
  const [hasIntersected, setHasIntersected] = React.useState(false);

  React.useEffect(() => {
    if (ref.current && isBrowser) {
      const handler = ([entry]) => {
        setIntersectionObserverEntry(entry);
        if (entry.isIntersecting) {
          setHasIntersected(true);
        }
      };

      const observer = new IntersectionObserver(handler, options);
      observer.observe(ref.current);

      return () => {
        setIntersectionObserverEntry(null);
        observer.disconnect();
      };
    }
    return () => {};
  }, [ref.current, options]);

  return [intersectionObserverEntry, hasIntersected];
}

export default useIntersection;
