import * as React from 'react';
import queryString from 'query-string';

const initialContext = {
  userLocation: undefined,
  changeUserLocation: () => undefined,
  addressQueryParams: undefined,
  addressQueryString: undefined,
};

const UserLocationContext = React.createContext(initialContext);

function UserLocationProvider({ children }) {
  const [userLocation, setUserlocation] = React.useState(undefined);
  // const { street, streetNumber, zip, city, country } = userLocation || {};

  function getQueryParamsForAddress(address) {
    const { street, streetNumber, zip, city, country } = address || {};
    return {
      'checkout[shipping_address][address1]':
        [street, streetNumber].filter((element) => element).join(' ') || null,
      'checkout[shipping_address][city]': city,
      'checkout[shipping_address][zip]': zip,
      'checkout[shipping_address][country]': country,
    };
  }

  function changeUserLocation(address) {
    setUserlocation(address);
    return getQueryParamsForAddress(address);
  }

  const addressQueryParams = React.useMemo(
    () => getQueryParamsForAddress(userLocation),
    [userLocation]
  );

  const addressQueryString = React.useMemo(
    () =>
      queryString.stringify(addressQueryParams, {
        skipEmptyString: true,
        skipNull: true,
      }),
    [userLocation]
  );

  return (
    <UserLocationContext.Provider
      value={{
        userLocation,
        changeUserLocation,
        addressQueryParams,
        addressQueryString,
      }}
    >
      {children}
    </UserLocationContext.Provider>
  );
}

export const useUserLocation = () => React.useContext(UserLocationContext);

export default UserLocationProvider;
