import fetch from 'isomorphic-fetch';
import Client from 'shopify-buy';

type Nullable<T> = null | undefined | T;

interface CustomAttribute {
  [string]: string;
}

interface LineItem {
  quantity: number;
  customAttributes: Nullable<CustomAttribute[]>;
}

interface NewLineItem extends LineItem {
  variantdId: number;
}

interface UpdatableLineItem extends LineItem {
  id: number;
}

const client = Client.buildClient(
  {
    storefrontAccessToken: process.env.GATSBY_SHOPIFY_ACCESS_TOKEN,
    domain: `app.nahschub.ch`,
  },
  fetch
);

async function fetchAllProducts() {
  return client.product.fetchAll(100);
}

async function fetchCheckout(checkoutId: number) {
  return client.checkout.fetch(checkoutId);
}

async function createCheckout() {
  return client.checkout.create();
}

async function addLineItem(checkoutId: number, lineItemToUpdate: NewLineItem) {
  return client.checkout.addLineItems(checkoutId, [lineItemToUpdate]);
}

async function addLineItems(
  checkoutId: number,
  lineItemsToUpdate: NewLineItem[]
) {
  return client.checkout.addLineItems(checkoutId, lineItemsToUpdate);
}

async function updateLineItem(
  checkoutId: number,
  lineItemToUpdate: UpdatableLineItem
) {
  return client.checkout.updateLineItems(checkoutId, [lineItemToUpdate]);
}

async function updateLineItems(
  checkoutId: number,
  lineItemsToUpdate: UpdatableLineItem[]
) {
  return client.checkout.updateLineItems(checkoutId, lineItemsToUpdate);
}

async function removeLineItem(checkoutId: number, lineItemId: number) {
  return client.checkout.removeLineItems(checkoutId, [lineItemId]);
}

async function removeLineItems(checkoutId: number, lineItemIds: number[]) {
  return client.checkout.removeLineItems(checkoutId, lineItemIds);
}

async function updateCheckoutAttribute(
  checkoutId: number,
  attribute: CustomAttribute
) {
  return client.checkout.updateAttributes(checkoutId, {
    customAttributes: [attribute],
  });
}

async function updateCheckoutAttributes(
  checkoutId: number,
  attributes: CustomAttribute[]
) {
  return client.checkout.updateAttributes(checkoutId, {
    customAttributes: attributes,
  });
}

interface ShippingAddress {
  address1: string;
  city: string;
  zip: string;
}

export default {
  fetchAllProducts,
  addLineItem,
  addLineItems,
  updateLineItem,
  updateLineItems,
  removeLineItem,
  removeLineItems,
  updateCheckoutAttribute,
  updateCheckoutAttributes,
  fetchCheckout,
  createCheckout,
};
