import * as React from 'react';
import { Link as GLink } from 'gatsby';
import styled from '@emotion/styled';
import theme from '../styles/theme';

const StyledLink = styled(GLink)(({ theme, withUnderline }) => ({
  color: 'inherit',
  textDecoration: withUnderline ? 'underline' : 'inherit',
  pointerEvents: 'auto',
}));

type Props = {
  children: React.ReactNode;
};

function Link({ children, ...restProps }: Props) {
  return <StyledLink {...restProps}>{children}</StyledLink>;
}

export default Link;
