import { Bag } from './assets';
import { css, jsx } from '@emotion/react';
import { ColorVariant } from '~/styles/theme';
import { useTheme } from '@emotion/react';
import { ShoppingBag, Menu, X } from 'react-feather';

const ICON_MAP = {
  bag: ShoppingBag,
  menu: Menu,
  x: X,
};

type IconType = keyof typeof ICON_MAP;

interface Props {
  icon: IconType;
  size: number;
  color: ColorVariant;
  stroke: number;
}

function Icon(props: Props) {
  const { icon, size, color, stroke } = props;
  const theme = useTheme();
  const IconSvg = ICON_MAP[icon];

  return (
    <IconSvg
      css={{
        stroke: color || theme.colors.green,
        strokeWidth: stroke || 1,
      }}
      {...{ size }}
    />
  );
}

export default Icon;
