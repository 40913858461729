import { eventSubject, EVENT_TYPE } from './eventSubject';
import { filter } from 'rxjs/operators';

export const NOTIFICATION_CODE = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
};

export function emitNotificationEvent({ message, code, color = 'sand' }) {
  eventSubject.next({
    type: EVENT_TYPE.NOTIFICATION,
    message,
    code,
    color,
  });
}

export const notificationEventSubject = eventSubject.pipe(
  filter((event) => event?.type === EVENT_TYPE.NOTIFICATION)
);
