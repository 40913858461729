import * as React from 'react';
import styled from '@emotion/styled';
import Link from '../Link';

export interface LinkTo {
  pathname: string;
  state?: Object;
}

export interface Props {
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  to?: LinkTo | string;
  children: React.ReactNode;
  className?: string;
  ref?: React.Ref<any>;
  href?: string;
  type: 'button' | 'submit';
  disabled?: boolean;
}

const defaultProps = {
  type: 'button',
  disabled: false,
} as Partial<Props>;

const Button = styled.button(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  WebkitTapHighlightColor: 'transparent',
  backgroundColor: 'transparent', // Reset default value
  outline: 0,
  border: 0,
  margin: 0, // Remove the margin in Safari
  borderRadius: 0,
  padding: 0, // Remove the padding in Firefox
  cursor: 'pointer',
  userSelect: 'none',
  verticalAlign: 'middle',
  MozAppearance: 'none',
  WebkitAppearance: 'none',
  textDecoration: 'none',
  font: 'inherit',
  fontWeight: 'inherit',
  fontSize: 'inherit',
  color: 'inherit',
  '&::-moz-focus-inner': {
    borderStyle: 'none', // Remove Firefox dotted outline.
  },
  '&:disabled': {
    // pointerEvents: 'none', // Disable link interactions
    cursor: 'default',
    filter: 'saturate(0)',
  },
}));

function ButtonBase({ type, children, disabled, onClick, to, className }) {
  const renderButton = () => (
    <Button
      onClick={disabled ? undefined : onClick}
      type={type}
      disabled={disabled}
      className={className}
    >
      {children}
    </Button>
  );

  if (to != null) {
    return <Link to={to}>{renderButton()}</Link>;
  }

  return renderButton();
}

ButtonBase.defaultProps = defaultProps;
export default ButtonBase;
