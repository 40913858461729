import * as React from 'react';
import styled from '@emotion/styled';
import Text from './Text';
import TextButton from './buttons/TextButton';

const COOKIE_CONSENT = 'cookie_consent';

const Container = styled.div(({ theme, show }) => ({
  position: 'fixed',
  zIndex: 2000,
  left: theme.spacing(3),
  right: theme.spacing(3),
  bottom: theme.spacing(3),
  backgroundColor: theme.colors.sand,
  color: theme.colors.green,
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'row',
  height: theme.sizes.bottomBar,
  transform: `translateY(${show ? 0 : 120}px)`,
  opacity: show ? 1 : 0,
  transitionDuration: `500ms`,
  borderRadius: 1000,
  [theme.breakpoints.down('sm')]: {
    left: theme.spacing(0),
    right: theme.spacing(0),
    bottom: theme.spacing(0),
    paddingBottom: theme.spacing(2),
    borderRadius: 0,
  },
}));

function CookieConsent() {
  const [
    showCookieConsentBanner,
    setShowCookieConsentBanner,
  ] = React.useState<boolean>(false);

  React.useEffect(() => {
    try {
      const askedForConsent = localStorage.getItem(COOKIE_CONSENT);
      if (!askedForConsent) {
        setShowCookieConsentBanner(true);
      }
    } catch (e) {
      //
    }
  }, []);

  function setConsentWithCookies() {
    const askedForConsent = localStorage.setItem(COOKIE_CONSENT, true);
    setShowCookieConsentBanner(false);
  }

  return (
    <Container show={showCookieConsentBanner}>
      <div css={{ alignItems: 'center', display: 'flex' }}>
        <span css={{ fontSize: '1.5rem' }} img="emoji">
          🍪
        </span>
        <span
          css={(theme) => ({
            padding: theme.spacing(0, 2),
            ...theme.fonts.futura,
          })}
        >
          Mir verwänded Cookies damit de Ichausbütel nöd verlore gaht
        </span>
      </div>
      <TextButton
        variant="subtitle"
        text={'OK'}
        onClick={setConsentWithCookies}
      />
    </Container>
  );
}

export default CookieConsent;
