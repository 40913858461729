import * as React from 'react';
import styled from '@emotion/styled';
import Logo from '../Logo';
import NavigationLinks from './NavigationLinks';
import BasketIcon from './mobile/BasketIcon';
import MenuIcon from './mobile/MenuIcon';
import Link from '../Link';
import { useTheme } from '@emotion/react';
import { useMediaQuery } from 'react-responsive';
import DeliveryStatusBanner from './DeliveryStatusBanner';
import { useApp } from '~/provider/AppProvider';
import ButtonBase from '../buttons/ButtonBase';
import { useDeliveryInfo } from '~/provider/DeliveryInfoProvider';
import { FaCentercode } from 'react-icons/fa';

const Container = styled.div(({ theme }) => ({
  height: theme.sizes.header,
  padding: theme.spacing(0, 2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  pointerEvents: 'none',
}));

const defaultContext = {
  headerHeight: 60,
};

function Header() {
  const theme = useTheme();
  const { headerHeight, setHeaderHeight, headerVisible } = useApp();
  const { deliveryStatus } = useDeliveryInfo();

  React.useEffect(() => {
    setHeaderHeight(theme.sizes.header);
    return () =>
      setHeaderHeight((prevHeight) => prevHeight - theme.sizes.header);
  }, []);

  function renderDeliveryStatus() {
    if (deliveryStatus?.message) {
      return (
        <div
          css={(theme) => ({
            backgroundColor: theme.colors.sand,
            color: theme.colors.green,
            fontWeight: 'bold',
            padding: theme.spacing(1),
            textAlign: 'center',
            ...theme.fonts.futura,
          })}
        >
          {deliveryStatus.message}
        </div>
      );
    }
    return null;
  }

  return (
    <div
      css={(theme) => ({
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1000,
        transform: `translateY(${headerVisible ? 0 : -200}px)`,
        transition: '0.5s',
        backgroundImage: `linear-gradient(${theme.colors.green}40, ${theme.colors.green}00)`,
      })}
    >
      {renderDeliveryStatus()}
      <Container>
        <MenuIcon {...{ headerVisible }} />
        <Link to="/">
          <Logo width={120} />
        </Link>
        <BasketIcon {...{ headerVisible }} />
      </Container>
    </div>
  );
}

export default Header;
