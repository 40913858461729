import * as React from 'react';
import firebase from '~/service/firebase';
import { interval } from 'rxjs';
import { DateTime } from 'luxon';

const initialContext = {
  isActiveDeliveryWindow: false,
  nextDeliveryTime: undefined,
};

const NUMBER_OF_DELIVERY_DATE_OPTIONS = 5;

const days = [
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
  'SUNDAY',
];

export const DAYS_TEXT = {
  MONDAY: 'Mäntig',
  TUESDAY: 'Zischtig',
  WEDNESDAY: 'Mittwoch',
  THURSDAY: 'Donnschtig',
  FRIDAY: 'Fritig',
  SATURDAY: 'Samstig',
  SUNDAY: 'Sunntig',
};

function stringToDatetime(time: string, date: DateTime = DateTime.local()) {
  const hoursMinutes = time
    .split(':')
    .map((timeComp) => parseInt(timeComp, 10));

  return date.set({
    hour: hoursMinutes[0],
    minute: hoursMinutes[1],
    second: 0,
  });
}

const refreshInterval = interval(1000 * 60 * 10); // Every 10 minutes

const DeliveryInfoContext = React.createContext();

function DeliveryInfoProvider({ children }) {
  const [deliveryTimes, setDeliveryTimes] = React.useState(null);
  const [isActiveDeliveryWindow, setIsActiveDeliveryWindow] = React.useState(
    false
  );
  const [nextDeliveryTime, setNextDeliveryTime] = React.useState(null);
  const [nextDeliveryWindows, setNextDeliveryWindows] = React.useState(null);
  const [deliveryStatus, setDeliveryStatus] = React.useState({
    open: true,
    message: '',
  });

  const [now, setNow] = React.useState(DateTime.local());
  const dayOfWeek = React.useMemo(() => now.weekday - 1, [now]);

  React.useEffect(() => {
    const subscription = refreshInterval.subscribe(() =>
      setNow(DateTime.local())
    );
    return () => subscription.unsubscribe();
  }, []);

  React.useEffect(() => {
    async function loadDeliveryInfo() {
      const deliveryTimesRef = firebase
        .firestore()
        .collection('delivery_information')
        .doc('delivery_times');
      const deliveryTimesDoc = await deliveryTimesRef.get();

      if (deliveryTimesDoc.exists) {
        setDeliveryTimes(deliveryTimesDoc.data());
      }

      const deliveryStatusRef = firebase
        .firestore()
        .collection('delivery_information')
        .doc('delivery_status');
      const deliveryStatusDoc = await deliveryStatusRef.get();

      if (deliveryStatusDoc.exists) {
        setDeliveryStatus(deliveryStatusDoc.data());
      }
    }
    loadDeliveryInfo();
  }, []);

  React.useEffect(() => {
    if (deliveryTimes) {
      let nextDeliveryDates = [];
      let i = 0;
      while (nextDeliveryDates.length < NUMBER_OF_DELIVERY_DATE_OPTIONS) {
        const weekDay = days[(dayOfWeek + i) % 7];
        if (Object.keys(deliveryTimes).includes(weekDay)) {
          const nextDeliveryDate = now.plus({ days: i });
          const deliveryHours = deliveryTimes[weekDay];

          const mappedDeliveryTimes = deliveryHours.map(({ start, end }) => ({
            start: stringToDatetime(start, nextDeliveryDate),
            end: stringToDatetime(end, nextDeliveryDate),
          }));

          nextDeliveryDates.push({
            date: nextDeliveryDate,
            deliveryWindows: mappedDeliveryTimes,
          });
        }
        i = i + 1;
      }
      setNextDeliveryWindows(nextDeliveryDates);
    }
  }, [deliveryTimes, now]);

  React.useEffect(() => {
    if (nextDeliveryWindows) {
      const { date, deliveryWindows } = nextDeliveryWindows[0];
      const isActive =
        date.startOf('day').equals(now.startOf('day')) &&
        deliveryWindows.some(
          (dw) => dw.start.minus({ minutes: 30 }) <= now && now < dw.end
        );
      setIsActiveDeliveryWindow(isActive);
    }
  }, [nextDeliveryWindows]);

  React.useEffect(() => {
    if (deliveryTimes) {
      const todaysDeliveryTimes = deliveryTimes[days[dayOfWeek]];

      if (todaysDeliveryTimes) {
        const activeDeliveryWindow = todaysDeliveryTimes.some(
          (deliveryWindow) => {
            const { start, end } = deliveryWindow;
            const startDate = stringToDatetime(start);
            const endDate = stringToDatetime(end);
            return (
              now.plus({ minutes: 30 }) >= startDate && // Shows as active as delivery 30 minutes before
              now < endDate
            );
          }
        );

        if (!activeDeliveryWindow) {
          const futureWindows = todaysDeliveryTimes.filter(
            ({ end }) => stringToDatetime(end) > now
          );
          let nextWindowStart;
          futureWindows.forEach(({ start }) => {
            nextWindowStart =
              !nextWindowStart || nextWindowStart > stringToDatetime(start)
                ? stringToDatetime(start)
                : nextWindowStart;
          });

          if (nextWindowStart) {
            const minutes = nextWindowStart.minute;
            setNextDeliveryTime({
              day: DAYS_TEXT[days[dayOfWeek]],
              startTime: `${nextWindowStart.hour}:${
                minutes.toString().length > 1 ? minutes : `0${minutes}`
              }`,
            });
          }
        }
      } else {
        const nextDay = days.findIndex((day) =>
          Object.keys(deliveryTimes).includes(day)
        );
        const nextDeliveryWindow = deliveryTimes[days[nextDay]];
        const nextWindowStart = stringToDatetime(nextDeliveryWindow[0].start);
        const minutes = nextWindowStart.minute;
        const nextWindowEnd = stringToDatetime(nextDeliveryWindow[0].start);
        const endMinutes = nextWindowEnd.minute;
        setNextDeliveryTime({
          day: DAYS_TEXT[days[nextDay]],
          startTime: `${nextWindowStart.hour}:${
            minutes.toString().length > 1 ? minutes : `0${minutes}`
          }`,
          endTime: `${nextWindowEnd.hour}:${
            nextWindowEnd.toString().length > 1
              ? nextWindowEnd
              : `0${nextWindowEnd}`
          }`,
        });
      }
    }
  }, [deliveryTimes, now]);

  return (
    <DeliveryInfoContext.Provider
      value={{
        isActiveDeliveryWindow,
        nextDeliveryTime,
        deliveryTimes,
        nextDeliveryWindows,
        deliveryStatus,
      }}
    >
      {children}
    </DeliveryInfoContext.Provider>
  );
}

export const useDeliveryInfo = () => React.useContext(DeliveryInfoContext);

export default DeliveryInfoProvider;
