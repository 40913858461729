import * as React from 'react';
import styled from '@emotion/styled';
import { jsx, css } from '@emotion/react';
import {
  notificationEventSubject,
  NOTIFICATION_CODE,
} from '~/service/events/notificationEvent';
import Text from './Text';
import { Check, X } from 'react-feather';
import theme from '../styles/theme';
import { useTheme } from '@emotion/react';

const SHOW_DURATION = 2.5 * 1000;
const TRANSITION_DURATION = 0.5 * 500;

const ICON_CODE_MAP = {
  [NOTIFICATION_CODE.SUCCESS]: Check,
  [NOTIFICATION_CODE.ERROR]: X,
};

const Toast = styled.div(({ theme, color }) => ({
  textAlign: 'center',
  borderRadius: 100,
  backgroundColor: color === 'sand' ? theme.colors.sand : theme.colors.green,
  padding: theme.spacing(2, 3),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const Container = styled.div(({ theme, show }) => ({
  position: 'fixed',
  zIndex: 1000,
  top: 0,
  left: 0,
  right: 0,
  paddingTop: theme.spacing(2),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  display: 'flex',
  transform: `translateY(${show ? 0 : '-100%'})`,
  transitionDuration: '0.5s',
  justifyContent: 'center',
  opacity: show ? 1 : 0,
  pointerEvents: 'none',
}));

function Notification() {
  const [notifications, setNotifications] = React.useState([]);
  const [currentNotification, setCurrentNotification] = React.useState(null);
  const [isHiding, setIsHiding] = React.useState(false);
  const theme = useTheme();

  const { message, code, link, color } = currentNotification || {};

  React.useEffect(() => {
    const subscription = notificationEventSubject.subscribe((event) =>
      setNotifications((prevNotifications) =>
        // Only add new notification if the same message is not already in the queue
        prevNotifications.find((prevNot) => prevNot.message === event.message)
          ? prevNotifications
          : [...prevNotifications, event]
      )
    );
    return () => subscription.unsubscribe();
  }, []);

  React.useEffect(() => {
    if (notifications.length > 0 && !currentNotification) {
      setCurrentNotification(notifications.pop());
    }
  }, [notifications, currentNotification]);

  React.useEffect(() => {
    if (currentNotification) {
      setIsHiding(false);
      const timeout = setTimeout(() => {
        setIsHiding(true);
      }, SHOW_DURATION);
      return () => clearTimeout(timeout);
    }
  }, [currentNotification]);

  React.useEffect(() => {
    if (currentNotification) {
      const timeout = setTimeout(() => {
        setCurrentNotification(null);
        setNotifications((prevNotifications) =>
          prevNotifications.slice(0, prevNotifications.length - 1)
        );
      }, SHOW_DURATION + TRANSITION_DURATION);
      return () => clearInterval(timeout);
    }
  }, [currentNotification]);

  function renderContent() {
    if (currentNotification) {
      const Icon = ICON_CODE_MAP[code];

      return (
        <>
          {Icon && (
            <Icon
              css={{
                color:
                  color === 'sand' ? theme.colors.green : theme.colors.sand,
                paddingRight: theme.spacing(2),
              }}
            />
          )}
          <span
            // variant="subtitle"
            css={{
              color: color === 'sand' ? theme.colors.green : theme.colors.sand,
              ...theme.fonts.futura,
            }}
          >
            {message}
          </span>
        </>
      );
    }
    return null;
  }

  const shouldShow = React.useMemo(() => !!currentNotification && !isHiding, [
    currentNotification,
    isHiding,
  ]);

  return (
    <Container show={shouldShow}>
      <Toast {...{ color }}>{renderContent()}</Toast>
    </Container>
  );
}

export default Notification;
