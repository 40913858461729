module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[],"layout":"/home/runner/work/nahschub/nahschub/src/components/Layout.tsx"},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"typekit":{"id":"cko7def"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"nachschub - scho uf em weg","short_name":"nahschub","description":"Lieferdienst für Drinks, Shots, Wein und Bier in der Stadt Zürich. Bestellt und innerhalb 30 Minuten geliefert","start_url":"/","background_color":"#FFF7E5","theme_color":"#043C17","display":"standalone","icon":"src/images/favicon.svg","legacy":false,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f860a9f452f3667c31195aa6a3518e45"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
