import * as React from 'react';
import styled from '@emotion/styled';
import Link from '../Link';
import Text from '../Text';
import { ColorVariant, TextVariant } from '~/styles/theme';

const StyledText = styled(Text)(
  ({
    theme,
    fillColor,
    activeFillColor,
    strokeColor,
    active,
    strokeWidth,
  }) => ({
    WebkitTextStrokeWidth: strokeWidth,
    WebkitTextStrokeColor: strokeColor || theme.colors.primary.green,
    color: active
      ? activeFillColor || theme.colors.primary.green
      : fillColor || 'transparent',
    '&:hover': {
      color: activeFillColor || theme.colors.primary.green,
      WebkitTextStrokeWidth: strokeWidth,
    },
    pointerEvents: 'auto',
  })
);

const Container = styled.div({
  cursor: 'pointer',
});

const ExternalLink = styled.a(({ theme }) => ({
  color: 'inherit',
  textDecoration: 'inherit',
  pointerEvents: 'auto',
}));

interface Props {
  fillColor: ColorVariant | null | undefined;
  activeFillColor: ColorVariant | null | undefined;
  strokeColor: ColorVariant | null | undefined;
  to: string;
  text: string;
  variant: TextVariant;
  className: string;
  active: boolean;
  strokeWidth: number;
  onClick: () => void;
}

function TextButton(props: Props) {
  const {
    active,
    to,
    onClick,
    fillColor,
    strokeColor,
    text,
    variant,
    className,
    activeFillColor,
    strokeWidth,
    external,
  } = props;

  if (typeof onClick === 'function') {
    return (
      <Container {...{ to, className }} onClick={onClick}>
        <StyledText
          {...{
            active,
            variant,
            activeFillColor,
            fillColor,
            strokeColor,
            text,
            strokeWidth,
          }}
        />
      </Container>
    );
  }

  if (external) {
    return (
      <ExternalLink href={to} target="_blank" {...{ className }}>
        <StyledText
          {...{
            active,
            variant,
            activeFillColor,
            fillColor,
            strokeColor,
            text,
            strokeWidth,
          }}
        />
      </ExternalLink>
    );
  }

  return (
    <Link {...{ to, className }}>
      <StyledText
        {...{
          active,
          variant,
          activeFillColor,
          fillColor,
          strokeColor,
          text,
          strokeWidth,
        }}
      />
    </Link>
  );
}

TextButton.defaultProps = {
  variant: 'title',
  className: '',
  strokeWidth: 1,
};

export default TextButton;
