import * as React from 'react';
import styled from '@emotion/styled';
import { useApp } from '~/provider/AppProvider';

const Container = styled.div(({ theme }) => ({
  display: 'flex',
  flex: 1,
  padding: theme.spacing(3),
  justifyContent: 'center',
  alignSelf: 'stretch',
}));

const InnerContainer = styled.div(({ fullWidth }) => ({
  maxWidth: fullWidth ? 'inherit' : 1000,
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  alignItems: 'stretch',
  alignSelf: 'stretch',
}));

function MainContainer({ children, className, fullWidth }, ref) {
  const { headerHeight } = useApp();
  return (
    <Container {...{ ref }}>
      <InnerContainer {...{ className, fullWidth }}>{children}</InnerContainer>
    </Container>
  );
}

export default React.forwardRef(MainContainer);
