import * as React from 'react';
import { useTheme } from '@emotion/react';
import { useLocation } from '@reach/router';

const initialContext = {
  headerHeight: 0,
  setHeaderHeight: () => undefined,
};

const AppContext = React.createContext(initialContext);

function AppProvider({ children }) {
  const { pathname } = useLocation();
  const theme = useTheme();
  const [headerHeight, setHeaderHeight] = React.useState<number>(
    theme.sizes.header
  );
  const [hasFooter, setHasFooter] = React.useState<boolean>(false);
  const [footerVisible, setFooterVisible] = React.useState<boolean>(false);
  const [headerVisible, setHeaderVisible] = React.useState<boolean>(
    pathname !== '/'
  );

  return (
    <AppContext.Provider
      value={{
        headerHeight,
        setHeaderHeight,
        hasFooter,
        setHasFooter,
        footerVisible,
        setFooterVisible,
        headerVisible,
        setHeaderVisible,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export const useApp = () => React.useContext(AppContext);

export default AppProvider;
