import * as React from 'react';
import { Global, css } from '@emotion/react';
import { BREAK_POINTS, colors } from './theme';
// font-size: 1.2vm;
// font-size: 1vmax;

const GlobalStyle = (props) => (
  <>
    <Global
      {...props}
      styles={css`
        body {
          margin: 0;
          overflow-x: hidden;
          background: ${colors.green};
        }

        html {
          font-family: sans-serif;
          font-size: 16px;
          -ms-text-size-adjust: 100%;
          -webkit-text-size-adjust: 100%;
          scroll-behavior: smooth;
          background: ${colors.green};
        }

        h1 {
          font-size: 2rem;
          line-height: 2.5rem;
          text-transform: uppercase;
        }

        @media screen and (min-width: ${BREAK_POINTS.md}px) {
          html {
            font-size: 115%;
          }
        }

        ::-moz-selection {
          background: ${colors.sand};
          color: ${colors.green};
        }
        ::selection {
          background: ${colors.sand};
          color: ${colors.green};
        }
      `}
    />
  </>
);

export default GlobalStyle;
