import * as React from 'react';
import { css, jsx } from '@emotion/react';
import { useTheme } from '@emotion/react';
import { TextVariant } from '~/styles/theme';

interface Props {
  text: string;
  component?: 'p' | 'div' | 'span';
  className?: string;
  variant: TextVariant;
}

function Text(props: Props) {
  const { text, component, className, children, variant = 'base' } = props;
  const theme = useTheme();

  const Component = component || 'span';

  function renderText() {
    if (text) {
      return text;
    }
    if (children) {
      return children;
    }
    return '';
  }

  return (
    <Component
      css={{
        ...(theme?.typography?.[props.variant] || {}),
      }}
      {...{ className }}
    >
      {renderText()}
    </Component>
  );
}

export default Text;
