import * as React from 'react';
import styled from '@emotion/styled';
import { useStore } from '~/provider/StoreProvider';
import { useTheme, css } from '@emotion/react';
import Icon from '~/components/Icon';
import Text from '~/components/Text';
import Link from '~/components/Link';
import ButtonBase from '../../buttons/ButtonBase';

const SIZE = 48;

const Container = styled.div(({ theme }) => ({
  width: SIZE,
  height: SIZE,
  borderRadius: '100%',
  overflow: 'hidden',
  background: theme.colors.primary.green,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  pointerEvents: 'auto',
}));

const OverlayContainer = styled(Container)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(-1),
  right: theme.spacing(-1),
  backgroundColor: theme.colors.primary.green,
  width: 20,
  height: 20,
  borderRadius: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const iconContainer = (theme) =>
  css({
    width: 36,
    height: 36,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 22,
    backgroundColor: theme.colors.sand,
    overflow: 'hidden',
  });

const CountText = styled(Text)(({ theme }) => ({
  fontSize: '0.8rem',
  ...theme.fonts.futura,
}));

function BasketIcon({ headerVisible }) {
  const theme = useTheme();
  const { store } = useStore();
  const [open, setOpen] = React.useState(false);
  const { count } = store?.cart || {};

  return (
    <Link
      to="/warenkorb"
      css={
        {
          // transform: `translateY(${headerVisible ? 0 : -200}px)`,
          // transition: '0.5s',
        }
      }
    >
      <ButtonBase css={iconContainer}>
        <Icon
          icon="bag"
          size="16"
          stroke={2}
          color={theme.colors.green}
          css={{ marginLeft: -3, marginTop: 2 }}
        />
      </ButtonBase>
    </Link>
  );
}

export default BasketIcon;
