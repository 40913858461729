export const colors = {
  background: '#FFF7E5',
  green: '#263F39',
  sand: '#FFF7E5',
  rose: '#D69A9A',
  olive: 'rgb(130, 143, 130)',
  primary: {
    green: '#01240D',
    olive: 'rgb(130, 143, 130)',
  },
  black: '#000',
  white: '#fff',
  title: '#EFA796',
  select: '#e28f8f',
  overlay: {
    dark: 'rgba(0,0,0,0.8)',
  },
} as const;

export type ColorVariant = keyof typeof colors;

const fonts = {
  courier: {
    fontFamily: 'botanika-mono-web, courier-std, monospace', //
    fontStyle: 'normal',
  },
  futura: {
    fontFamily: 'futura-pt, sans-serif',
    fontStyle: 'normal',
  },
} as const;

export type FontVariant = keyof typeof fonts;

const SPACING = 8;

export const BREAK_POINTS = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920,
};

const breakpoints = {
  ...BREAK_POINTS,
  up: (min: keyof typeof BREAK_POINTS) =>
    `@media (min-width: ${BREAK_POINTS[min]}px)`,
  down: (max: keyof typeof BREAK_POINTS) =>
    `@media (max-width: ${BREAK_POINTS[max]}px)`,
  between: (min: keyof typeof BREAK_POINTS, max: keyof typeof BREAK_POINTS) =>
    `@media (max-width: ${BREAK_POINTS[max]}px) and (min-width: ${BREAK_POINTS[min]}px)`,
};

const zIndex = {
  bottomBar: 100,
  menu: 2000,
  menuBar: 2001,
  selection: 6000,
} as const;

const typography = {
  base: {
    ...fonts.courier,
    fontWeight: 400,
    color: colors.primary.olive,
    lineHeight: '1.3rem',
  },
  title: {
    ...fonts.futura,
    fontWeight: 600,
    fontSize: '2.2rem',
    color: colors.primary.green,
  },
  h1: {
    fontWeight: 600,
    fontSize: '4rem',
    lineHeight: '5.5rem',
    color: colors.sand,
    wordWrap: 'break-word',
    hyphens: 'auto',
    [breakpoints.down('sm')]: {
      fontSize: '3.5rem',
      lineHeight: '3.7rem',
    },
  },
  h3: {
    ...fonts.futura,
    fontSize: '1.5rem',
    color: colors.primary.green,
    fontWeight: 600,
    wordWrap: 'break-word',
    hyphens: 'auto',
  },
  h5: {
    ...fonts.futura,
    fontSize: '1.3rem',
    color: colors.primary.green,
  },
  h6: {
    ...fonts.futura,
    fontWeight: 600,
    fontSize: '1.2rem',
    textTransform: 'uppercase',
    color: colors.green,
  },
  subtitle: {
    // ...fonts.futura,
    fontSize: '1rem',
    color: colors.green,
  },
  outlined: {
    WebkitTextStrokeWidth: 1,
    WebkitTextStrokeColor: colors.green,
    color: colors.background,
  },
} as const;

export type TextVariant = keyof typeof typography;

function spacing(...args) {
  if (args.length > 0 && args.length <= 4) {
    return args.map((arg) => `${SPACING * arg}px`).join(' ');
  }
  console.warn('Between one and four arguments must be provided for spacing');
  return '';
}

const sizes = {
  header: SPACING * 8,
  bottomBar: SPACING * 8,
};

const css = {
  absolutePosition: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
} as const;

const theme = {
  colors,
  typography,
  breakpoints,
  spacing,
  sizes,
  fonts,
  zIndex,
  css,
};

export default theme;
