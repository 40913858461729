import { eventSubject, EVENT_TYPE } from './eventSubject';
import { filter } from 'rxjs/operators';

export const REFRESH_KEY = {
  CHECKOUT: 'CHECKOUT',
};

export function emitRefreshEvent(refreshKey) {
  eventSubject.next({
    type: EVENT_TYPE.REFRESH,
    refreshKey,
  });
}

export const refreshEventSubject = eventSubject.pipe(
  filter((event) => event?.type === EVENT_TYPE.REFRESH)
);

export const getRefreshEventSubject = (refreshKey) => {
  return refreshEventSubject.pipe(
    filter((event) => event?.refreshKey === refreshKey)
  );
};
