export function mapOrder(array, sortOrder, keyMapper) {
  array.sort((a, b) => {
    const A = keyMapper(a);
    const B = keyMapper(b);
    if (sortOrder.indexOf(A) > sortOrder.indexOf(B)) {
      return 1;
    } else {
      return -1;
    }
  });
  return array;
}
