import * as React from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import Icon from '~/components/Icon';
import Text from '~/components/Text';
import Link from '~/components/Link';
import TextButton from '~/components/buttons/TextButton';
import ButtonBase from '~/components/buttons/ButtonBase';

import { NAV_ITEMS } from '../NavigationLinks';
import { useLocation } from '@reach/router';
import useLockBodyScroll from '~/hooks/useLockBodyScroll';
import { navigate } from 'gatsby';
import { FaInstagram } from 'react-icons/fa';

const MAIN_LINKS = [
  {
    label: 'Home',
    path: '/',
  },
  {
    label: 'Menücharte',
    path: '/menuecharte',
    description: 'Shop',
  },
  {
    label: 'Über eus',
    path: '/ueber',
  },
];

const MenuButton = styled.div(({ theme }) => ({
  cursor: 'pointer',
  pointerEvents: 'auto',
  zIndex: 2001,
}));

const BEZIER = 'cubic-bezier(0.820, 0.000, 0.275, 1.005)';

const Menu = styled.div(({ theme, open }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  height: '100vh',
  backgroundColor: theme.colors.background,
  transitionDuration: '0.5s',
  pointerEvents: open ? 'auto' : 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  flex: 1,
  transitionDelay: 0,
  zIndex: theme.zIndex.menu,
  visibility: open ? 'visible' : 'hidden',
  transform: `translateY(${open ? 0 : '-100%'})`,
  transitionTimingFunction: BEZIER,
}));

const Container = styled.div(({ theme, open }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  flex: 1,
}));

const NavigationButton = styled(TextButton)(({ theme }) => ({
  marginBottom: theme.spacing(4),
}));

function MenuIcon() {
  const theme = useTheme();
  const [open, setOpen] = React.useState<boolean>(false);
  const { pathname } = useLocation();

  useLockBodyScroll(open);

  function handleOpenMenu() {
    setOpen((prevOpen) => !prevOpen);
  }

  return (
    <>
      <Menu {...{ open }}>
        <Container>
          {MAIN_LINKS.map((item, idx) => (
            <Link
              key={item.path}
              onClick={() => setOpen(false)}
              to={item.path}
              css={{
                marginBottom: theme.spacing(3),
                '&:last-of-type': { marginBottom: theme.spacing(0) },
              }}
            >
              <div
                css={{
                  transform: `translateY(${open ? 0 : 30}px)`,
                  transitionDelay: open ? `${0.5 + idx * 0.1}s` : 0,
                  opacity: open ? 1 : 0,
                  transitionDuration: '0.5s',
                  transitionTimingFunction: 'ease-out',
                }}
              >
                <span
                  css={{
                    lineHeight: '3rem',
                    fontSize: '3rem',
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                    color: theme.colors.green,
                    '&:hover': {
                      fontStyle: 'italic',
                    },
                  }}
                >
                  {item.label}
                  {item.description && (
                    <span css={{ fontSize: '1rem', color: theme.colors.olive }}>
                      {item.description}
                    </span>
                  )}
                </span>
              </div>
            </Link>
          ))}
        </Container>

        <div
          css={{
            padding: theme.spacing(5),
            opacity: open ? 1 : 0,
            transitionDuration: '1s',
            transitionDelay: open ? '1.2s' : 0,
          }}
        >
          <a
            href="https://www.instagram.com/nahschub/"
            target="_blank"
            css={{
              border: `2px solid ${theme.colors.green}`,
              borderRadius: 1000,
              width: 44,
              height: 44,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              transition: '0.5s',
              color: theme.colors.green,
              '&:hover': {
                backgroundColor: theme.colors.green,
                color: theme.colors.sand,
              },
            }}
          >
            <FaInstagram size={20} />
          </a>
        </div>
      </Menu>

      <ButtonBase
        css={(theme) => ({
          zIndex: theme.zIndex.menuBar,
          textTransform: 'uppercase',
          fontWeight: 'bold',
          pointerEvents: 'auto',
        })}
        onClick={handleOpenMenu}
      >
        <div css={{ overflow: 'hidden' }}>
          <span
            css={{
              color: open ? theme.colors.green : theme.colors.sand,
              transition: '0.5s',
            }}
          >
            {open ? 'ZUE MACHE' : 'MENÜ'}
          </span>
        </div>
      </ButtonBase>
    </>
  );
}

export default MenuIcon;
