// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-lieferinformationen-tsx": () => import("./../../../src/pages/lieferinformationen.tsx" /* webpackChunkName: "component---src-pages-lieferinformationen-tsx" */),
  "component---src-pages-lieferoptione-tsx": () => import("./../../../src/pages/lieferoptione.tsx" /* webpackChunkName: "component---src-pages-lieferoptione-tsx" */),
  "component---src-pages-menuecharte-tsx": () => import("./../../../src/pages/menuecharte.tsx" /* webpackChunkName: "component---src-pages-menuecharte-tsx" */),
  "component---src-pages-ueber-tsx": () => import("./../../../src/pages/ueber.tsx" /* webpackChunkName: "component---src-pages-ueber-tsx" */),
  "component---src-pages-warenkorb-tsx": () => import("./../../../src/pages/warenkorb.tsx" /* webpackChunkName: "component---src-pages-warenkorb-tsx" */),
  "component---src-templates-category-tsx": () => import("./../../../src/templates/category.tsx" /* webpackChunkName: "component---src-templates-category-tsx" */),
  "component---src-templates-legal-tsx": () => import("./../../../src/templates/legal.tsx" /* webpackChunkName: "component---src-templates-legal-tsx" */),
  "component---src-templates-product-tsx": () => import("./../../../src/templates/product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */)
}

