import * as React from 'react';
import styled from '@emotion/styled';
import { ThemeProvider } from '@emotion/react';
import { GlobalStyle, theme } from '~/styles';
import useSiteMetaData from '~/hooks/useSiteMetaData';

import StoreProvider from '~/provider/StoreProvider';
import AppProvider from '~/provider/AppProvider';
import DeliveryInfoProvider from '~/provider/DeliveryInfoProvider';

import Header from './Header';
import Notification from './Notification';
import CookieConsent from './CookieConsent';
import Footer from './Footer';
import { Helmet } from 'react-helmet';
import UserLocationProvider from '../provider/UserLocationProvider';

const SPACING = 2;

const LayoutContainer = styled.div(({ theme }) => ({
  minHeight: `100vh`,
  width: '100%',
  // padding: theme.spacing(SPACING),
  // backgroundColor: theme.colors.green,
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  position: 'relative',
  justifyContent: 'center',
  // alignItems: 'stretch',

  // TODO: Don't allow to select -> include for mobile only
  webkitTouchCallout: 'none',
  webkitUserSelect: 'none',
  khtmlUserSelect: 'none',
  mozUserSelect: 'none',
  msUserSelect: 'none',
  userSelect: 'none',
  //
  ...theme.typography.base,
}));

function Layout(props: Props) {
  const { children } = props;

  return (
    <ThemeProvider theme={theme}>
      <AppProvider>
        <DeliveryInfoProvider>
          <StoreProvider>
            <UserLocationProvider>
              <GlobalStyle />
              <Helmet>
                <meta
                  name="viewport"
                  content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
                />
                <link
                  rel="apple-touch-icon"
                  sizes="48x48"
                  href="/icons/apple-icon-48x48.png"
                />
                <link
                  rel="apple-touch-icon"
                  sizes="72x72"
                  href="/icons/apple-icon-72x72.png"
                />
                <link
                  rel="apple-touch-icon"
                  sizes="96x96"
                  href="/icons/apple-icon-96x96.png"
                />
                <link
                  rel="apple-touch-icon"
                  sizes="144x144"
                  href="/icons/apple-icon-144x144.png"
                />
                <link
                  rel="apple-touch-icon"
                  sizes="192x192"
                  href="/icons/apple-icon-192x192.png"
                />
                <link
                  rel="apple-touch-icon"
                  sizes="256x256"
                  href="/icons/apple-icon-256x256.png"
                />
                <link
                  rel="apple-touch-icon"
                  sizes="384x384"
                  href="/icons/apple-icon-384x384.png"
                />
                <link
                  rel="apple-touch-icon"
                  sizes="512x512"
                  href="/icons/apple-icon-512x512.png"
                />
              </Helmet>
              <LayoutContainer>
                <Header />
                {children}
                <Footer />
                <Notification />

                <CookieConsent />
              </LayoutContainer>
            </UserLocationProvider>
          </StoreProvider>
        </DeliveryInfoProvider>
      </AppProvider>
    </ThemeProvider>
  );
}

export default Layout;
