import * as React from 'react';
import { ColorVariant } from '~/styles/theme';
import { LogoSvg } from './assets';
import { css, jsx } from '@emotion/react';
import { useTheme } from '@emotion/react';

interface Props {
  variant: 'outlined' | 'filled';
  fillColor: ColorVariant;
  strokeColor: ColorVariant;
  width: number;
  fullWidth: boolean;
  className: string;
}

function Logo(props) {
  const {
    variant,
    fillColor,
    strokeColor,
    width,
    fullWidth,
    className,
  } = props;
  const theme = useTheme();

  return (
    <LogoSvg
      css={{
        width: fullWidth ? undefined : width,
        strokeWidth: variant === 'outlined' ? 0.75 : 0,
        stroke: strokeColor || theme.colors.green,
        fill: variant === 'filled' ? fillColor || theme.colors.sand : 'none',
      }}
      {...{ className }}
    />
  );
}

Logo.defaultProps = {
  variant: 'filled',
  width: 120,
};

export default Logo;
